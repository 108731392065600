<template>
  <div style="width:100%;height:100%" >
    <img style="width:100%;height:100%" src="@/assets/home.png" alt />
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "index",
  data() {
    return {
    
    };
  },
  methods: {
  },
});
</script>
<style lang="less">

</style>
        